export const solutionsSection = [
    {
        header: { title: "Request a tutor", item_link: 1 },
    },
    
    {
        header: { title: "Tutor Job", item_link: 2 },
    },
 

]

export const servivessection = [
    {
        header: { title: "Professional Development Courses", item_link: '/welcome' },
    },
    {
        header: { title: "Academic Tutoring", item_link: '/mobile' },
    },
    {
        header: { title: "Tutors", item_link: '/angular' },
    },
    {
        header: { title: "Institutions", item_link: '/wordpress' },
    },
 

]