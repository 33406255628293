import whatsappDp from '../src/assets/whatsappDp.jpg'



// CONTROL full website look from this point
export const MAIN_COLOR = '#2979ff'
export const SECONDARY_COLOR = 'white'
export const HOVER_COLOR = '#2979ff'
export const MOBILE = '+121 09861774'
export const MOBILE2 = '+233 531463666'
export const AVATAR_IMAGE = whatsappDp
export const FONT_FAMILY = '"Times New Roman", Times, serif'
